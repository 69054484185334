import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const [title, setTitle] = useState("Verzonal");
  useEffect(() => {
    axios.get("api/metadata/gettitle").then((response) => {
      setTitle(response.data);
    });
  }, []);

  return (
    <div>
      <header>
        <a href="https://www.verzonal.com">
          <h1 className="text-3xl font-bold underline">{title}</h1>
        </a>
      </header>
    </div>
  );
}

export default App;
